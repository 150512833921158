import React from 'react';

import Layout from '@components/Layout/Layout';
import Seo from '@components/Seo/Seo';
import Itinerari from '@components/Itinerari/Itinerari';

const ItinerariPage = ({ location }) => {
  return (
    <Layout location={location} en>
      <Seo
        title="Recommended E-Bike itineraries - Tiziano Bieller Sport"
        lang="en"
      />
      <Itinerari en />
    </Layout>
  );
};

export default ItinerariPage;
